import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import useFbq from "../hooks/useFbq";

const SubscribeSchema = Yup.object().shape({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  first_name: Yup.string(),
});

function PostSubmissionMessage() {
  return (
    <div>
      <h4 className="has-text-left" style={{ color: "white" }}>
        Thank you - watch your inbox ;)
      </h4>
    </div>
  );
}

function fetchReducer(state, { type, response, error }) {
  switch (type) {
    case "fetching": {
      return { error: null, response: null, pending: true };
    }
    case "success": {
      return { error: null, response, pending: false };
    }
    case "error": {
      return { error, response: null, pending: false };
    }
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
}

function useFetch({ url, body }) {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    error: null,
    response: null,
    pending: false,
  });
  const bodyString = JSON.stringify(body);

  React.useEffect(() => {
    if (url && bodyString) {
      dispatch({ type: "fetching" });
      fetch(url, {
        method: "post",
        body: bodyString,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json())
        .then(
          (response) => dispatch({ type: "success", response }),
          (error) => dispatch({ type: "error", error })
        );
    }
  }, [url, bodyString]);

  return state;
}

function Checkbox({ field, type }) {
  return (
    <div className="has-text-left">
      <label>
        {/* remove {...field} to see changes not propagated */}
        <input {...field} type={type} /> <strong>{field.name}</strong>
      </label>
    </div>
  );
}

export default function SubscribeForm({
  title = "Stay up to date!",
  initialValues = null,
}) {
  const fbq = useFbq();

  const [values, setValues] = React.useState();
  const { pending, response, error } = useFetch({
    url: `/.netlify/functions/newsletter-subscribe`,
    body: values,
  });

  const errorMessage = error ? "Something went wrong!" : null;
  const submitted = Boolean(response);
  const successful = response && response.status === "success";

  React.useEffect(() => {
    if (successful) {
      fbq("Contact", {
        content_ids: Object.keys(values).filter(
          (x) => x !== "email_address" && x !== "first_name"
        ),
      });
    }
  }, [successful, fbq, values]);

  return (
    <div className="subscribe-form">
      <h3>{title}</h3>
      {!successful && (
        <Formik
          initialValues={{
            email_address: "",
            first_name: "",
          }}
          validationSchema={SubscribeSchema}
          onSubmit={(values) => {
            setValues({ ...values, ...initialValues });
          }}
          render={() => (
            <Form noValidate={true}>
              <label htmlFor="first_name">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <strong>First Name</strong>
                  <ErrorMessage
                    name="first_name"
                    component="span"
                    className="footer field-error"
                  />
                </div>
              </label>
              <Field
                id="first_name"
                aria-required="false"
                name="first_name"
                placeholder="Frank"
                type="text"
              />
              <label htmlFor="email">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignIitems: "flex-end",
                  }}
                >
                  <strong>Email</strong>
                  <ErrorMessage
                    name="email_address"
                    component="span"
                    className="field-error"
                  />
                </div>
              </label>
              <Field
                id="email"
                aria-required="true"
                name="email_address"
                placeholder="frank@thetank.com"
                type="email"
              />

              {/* interests */}
              {initialValues !== null && (
                <p style={{ color: "#000" }}>Also interested in...</p>
              )}
              <Field
                id="fatloss"
                aria-required="false"
                name="Fat Loss"
                type="checkbox"
                component={Checkbox}
              />
              <Field
                id="musclebuilding"
                aria-required="false"
                name="Muscle Building"
                type="checkbox"
                component={Checkbox}
              />
              <Field
                id="nutrition"
                aria-required="false"
                name="Nutrition/Recipes"
                type="checkbox"
                component={Checkbox}
              />
              <div className="has-text-left">
                <button
                  className="fancy subscribe-button"
                  data-element="submit"
                  type="submit"
                >
                  {!pending && "Subscribe"}
                  {pending && "Submitting..."}
                </button>
              </div>
            </Form>
          )}
        />
      )}
      {submitted && !pending && <PostSubmissionMessage response={response} />}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
}
