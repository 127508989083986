import React from "react";
import Layout from "../../components/Layout";
import banner from "../../img/promos/macros-ebook.jpg";
import SubscribeForm from "../../components/SubscribeForm";

function PromoPage() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <img
          src={banner}
          alt={"Back to Basix - Free eBook"}
          style={{ maxHeight: 300 }}
        />
      </div>

      <div className="promo-content">
        <div
          style={{
            paddingLeft: "1em",
            paddingRight: "1em",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
            maxWidth: 500,
          }}
        >
          <h1 className="is-size-1 has-text-weight-bold has-text-primary has-text-centered">
            Enter your email to calculate your macros FREE!
          </h1>
          <ul className="promo">
            <li>
              Let's take you through a step by step process to calculate your
              macros!! This will help you reach your specific goals with a
              "balanced lifestyle" approach!
            </li>
            <li>
              By entering your email, you will receive your FREE e-book and stay
              up to date on recipes, workout tips and promotions!{" "}
            </li>
          </ul>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "1em" }}
        >
          <SubscribeForm title="" initialValues={{ Macros: true }} />
        </div>
      </div>
    </div>
  );
}

const MacrosFinal = () => (
  <Layout>
    <PromoPage />
  </Layout>
);

export default MacrosFinal;
